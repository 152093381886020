export enum COMMON_ROUTES {
  HOME = "/",
  ABOUT_US = "/about-us",
  CONTACT = "/contact-us",
  OUR_SECTORS = "/our-sectors",
  OUR_SERVICES = "/our-services",
  TERM_AND_CONDTION = "/terms-and-conditions",
  PRIVACY_POLICY = "/privacy-policy",
  WHY_CHOOSE = "/why-choose-us",
  RURAL_INSURANCE = "/rural-insurance",
  CROP_INSURANCE = "/crop-insurance",
  WEATHER_INSURANCE = "/weather-insurance",
  IPAR = "/insurance-portfolio-analysis-report",
  IPAR_FORM = "/insurance-portfolio-analysis-report/form",
  CLAIMS_CONSULTANCY = "/claims-consultancy",
  CLAIMS_CONSULTANCY_SERVICES = "/claims-consultancy-services",
}

import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import MNavBar from "../../../Components/Mobile/MNavBar/MNavBar";
import Navbar from "../../../Components/Desktop/Navbar/Navbar";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import MFooter from "../../../Components/Mobile/MFooter/MFooter";
import Footer from "../../../Components/Desktop/Footer/Footer";
import CustomButton from "../../../Components/InputFields/CustomButton/CustomButton";
import { COMMON_ROUTES } from "../../../Routing/Path/CommonPaths";
import { useNavigate } from "react-router-dom";
import EastIcon from "@mui/icons-material/East";

const ClaimsConsultancy = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  return (
    <Box className="productPages-layout">
      {isMobile ? <MNavBar /> : <Navbar />}

      <Box className="pp-inner">
        <Box className="main_pp">
          <Grid
            container
            columnSpacing={3}
            className="row mb-margin2"
            marginBottom={"40px"}
          >
            <Grid xs={12} textAlign={"center"} className="mb-6">
              <h2 className="mb-6">
                End-to-End Claims Consultancy Services for Corporates
              </h2>
              <h5 className="mb-3">
                Resolving Insurance Claims Quickly, Efficiently, and Expertly
              </h5>
              <p>
                At Amaze Insurance Brokers, we understand that navigating
                insurance claims can often be challenging and time-consuming.
                That’s why we provide specialized Claims Consultancy Services
                designed to assist clients in resolving their pending claims or
                addressing issues in claim settlements efficiently.
              </p>
            </Grid>
            <Grid xs={12} className="mb-6">
              <h5 className="mb-3">We Simplify Complex Corporate Claims </h5>
              <p>
                We specialize in assisting businesses with resolving their
                insurance claims seamlessly. Whether your claim is pending or
                facing settlement challenges, our team is here to guide you
                every step of the way. Backed by years of expertise, we handle a
                broad spectrum of corporate claims across industries.
              </p>
            </Grid>
            <Grid xs={12} className="mb-6">
              <h3 className="mb-3">Our Expertise </h3>
              <p>
                With our vast experience and industry insights, we are equipped
                to manage claims across various sectors:
              </p>
              <ol type="1" className="ordered-list mt-3">
                <li>
                  <span>Fire, Flood and Earthquake(AOG Perils) Claims : </span>{" "}
                  specializedsupport for high stakes natural disaster claims.
                </li>
                <li>
                  <span>Business Interruption Claims : </span>minimizing
                  financial losses during operational downtimwes.
                </li>
                <li>
                  <span>Marin Claims: </span> covering cargo damage, shipping
                  delays and logistics related incidents.
                </li>
                <li>
                  <span>Project Claims: </span>Expertise in Erection All Risks
                  and Contractors All Risks policies.
                </li>
                <li>
                  <span>Machinery Breakdown Claims: </span>Reducing downtime
                  with swift claim resolution.
                </li>
              </ol>
            </Grid>
            <Grid xs={12} className="mb-6">
              <h3 className="mb-4">Our 4-Step Process </h3>
              <h5 className="mb-3">How We Work to Resolve Your Claims</h5>

              <ul className="mt-3">
                <li>
                  <span>Client Authorization:</span> We act upon the client's
                  authorization, ensuring full transparency and compliance.
                </li>
                <li>
                  <span>Claim Analysis:</span> A thorough review of the claim’s
                  documentation and circumstances.
                </li>
                <li>
                  <span>Negotiation & Advocacy:</span> Liaising with insurers to
                  achieve the best possible outcome.
                </li>
                <li>
                  <span>Settlement Assistance:</span> Guiding clients through
                  the settlement process with clear, actionable advice
                </li>
              </ul>
            </Grid>
          </Grid>

          <Box
            className="whyChoose_pp_section mb-margin1"
            marginBottom={"80px"}
          >
            <Grid
              container
              columnSpacing={3}
              className="row mb-6"
              alignItems={"center"}
            >
              <Grid xs={12} md={6} className="mb-margin2">
                <h2>Why Choose Amaze Insurance Brokers?</h2>
                <img
                  src="../images/whychoose_pp_img.svg"
                  alt=""
                  style={{ maxWidth: "450px", width: "100%", margin: "auto" }}
                  className="wchoose_img"
                />
              </Grid>
              <Grid xs={12} md={6}>
                <ul className="whyChoosepp_list">
                  <li>
                    <Box className="wc_box">
                      <img src="../images/wc_img1.svg" alt="" />
                      <h6>Proven Track Record</h6>
                      <p>Success in managing complex and high-value claims.</p>
                    </Box>
                    <Box className="wc_box">
                      <img src="../images/wc_img2.svg" alt="" />
                      <h6>Industry Expertise</h6>
                      <p>
                        Deep knowledge of insurance regulations and practices.
                      </p>
                    </Box>
                  </li>
                  <li>
                    <Box className="wc_box">
                      <img src="../images/wc_img3.svg" alt="" />
                      <h6>Personalized Support</h6>
                      <p>
                        Tailored solutions for every corporate client’s unique
                        needs.
                      </p>
                    </Box>
                    <Box className="wc_box">
                      <img src="../images/wc_img4.svg" alt="" />
                      <h6>Time & Cost Efficiency</h6>
                      <p>
                        Optimized processes to save your time and resources.
                      </p>
                    </Box>
                  </li>
                </ul>
              </Grid>
            </Grid>
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} textAlign={"center"}>
                <CustomButton
                  className="primaryBtn animatedbtn"
                  fullWidth={false}
                  variant="contained"
                  text={
                    <>
                      Get Expert Assistance Now
                      <EastIcon />
                    </>
                  }
                  onClick={() =>
                    navigate(COMMON_ROUTES.CLAIMS_CONSULTANCY_SERVICES)
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default ClaimsConsultancy;

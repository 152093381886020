import { Box } from "@mui/material";
import { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import MNavBar from "../../../../Components/Mobile/MNavBar/MNavBar";
import Navbar from "../../../../Components/Desktop/Navbar/Navbar";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import MFooter from "../../../../Components/Mobile/MFooter/MFooter";
import Footer from "../../../../Components/Desktop/Footer/Footer";
import CustomButton from "../../../../Components/InputFields/CustomButton/CustomButton";
import RKTextField from "../../../../Components/InputFields/RKTextField/RKTextField";
import CustomCheckbox from "../../../../Components/InputFields/CustomCheckbox/CustomCheckbox";
import DocUpload from "../../../../Components/DocUpload/DocUpload";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import SelectDropdown from "../../../../Components/InputFields/SelectDropdown/SelectDropdown";
import {
  isEmpty,
  validateEmail,
  validateFullName,
  validateMobileNumber,
} from "../../../../SupportingFiles/HelpingFunction";

const ClaimsConsultancyServices = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const [formFields, setFormFields] = useState<{
    companyName: { value: string; warn_status: boolean };
    name: { value: string; warn_status: boolean };
    mobile: { value: string; warn_status: boolean };
    email: { value: string; warn_status: boolean };
    claimType: { value: string; warn_status: boolean };
    additionalInfo: { value: string; warn_status: boolean };
    policyDoc: {
      value: any;
      fileName: string;
      fileType: string;
      warn_status: boolean;
    };
    termsAndConditions: { value: boolean; warn_status: boolean };
  }>({
    companyName: { value: "", warn_status: false },
    name: { value: "", warn_status: false },
    mobile: { value: "", warn_status: false },
    email: { value: "", warn_status: false },
    claimType: { value: "", warn_status: false },
    additionalInfo: { value: "", warn_status: false },
    policyDoc: {
      value: null,
      fileName: "",
      fileType: "",
      warn_status: false,
    },
    termsAndConditions: { value: false, warn_status: false },
  });

  const updateFieldsState = (attrName: string, value: string | Boolean) => {
    setFormFields((prev) => ({ ...prev, [attrName]: { value: value } }));
  };

  const handleFileSelect = (files: FileList | null, fieldName: any) => {
    if (files && files.length > 0) {
      const file = files[0];

      setFormFields((prev) => ({
        ...prev,
        [fieldName]: {
          value: file,
          fileName: files[0].name,
          fileType: file.type,
          warn_status: false,
        },
      }));
    } else {
      setFormFields((prev) => ({
        ...prev,
        [fieldName]: {
          value: null,
          fileName: "",
          fileType: "",
          warn_status: true,
        },
      }));
    }
  };

  const validateForm = () => {
    let hasError = false;
    let data = { ...formFields };

    data = {
      ...data,
      name: { ...data.name, warn_status: !validateFullName(data.name.value) },
      companyName: {
        ...data.companyName,
        warn_status: isEmpty(data.companyName.value),
      },
      email: { ...data.email, warn_status: !validateEmail(data.email.value) },
      mobile: {
        ...data.mobile,
        warn_status: !validateMobileNumber(data.mobile.value),
      },
      claimType: {
        ...data.claimType,
        warn_status: isEmpty(data.claimType.value),
      },
    };
    setFormFields(data);

    if (
      data.name.warn_status ||
      data.companyName.warn_status ||
      data.email.warn_status ||
      data.mobile.warn_status ||
      data.claimType.warn_status
    ) {
      hasError = true;
    }

    if (!hasError) {
      handleSubmit();
    }
  };
  const handleSubmit = () => {
    console.log("submitted");
  };

  return (
    <Box className="productPages-layout">
      {isMobile ? <MNavBar /> : <Navbar />}

      <Box className="pp-inner">
        <Box className="main_pp">
          <Grid
            container
            columnSpacing={3}
            className="row mb-margin2"
            marginBottom={"40px"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Grid xs={12} textAlign={"center"} className="mb-6">
              <h2 className="mb-6">
                "Claims Consultancy Services – Expertise You <br />
                Can Rely On"
              </h2>
            </Grid>
            <Grid xs={12} md={6}>
              <h3 style={{ color: "#1E2E5F", marginBottom: "60px" }}>
                Simplifying Insurance Claims for Corporates
              </h3>
              <img src="./images/claim_services_image.svg" alt="" />
            </Grid>
            <Grid xs={12} md={6}>
              <Box className="formBox">
                <Grid container columnSpacing={3} marginBottom={"24px"}>
                  <Grid xs={12} textAlign={"center"}>
                    <h5>Let Us Help You Today!</h5>
                  </Grid>
                </Grid>
                <Grid container columnSpacing={3}>
                  <Grid xs={12} md={6}>
                    <RKTextField
                      class_name="inputField mb-6"
                      title={"Name"}
                      value={formFields.name.value}
                      attrName={"name"}
                      value_update={updateFieldsState}
                      validation_type="NAME"
                      warn_status={formFields.name.warn_status}
                      error_message="Enter full name"
                    />
                  </Grid>
                  <Grid xs={12} md={6}>
                    <RKTextField
                      class_name="inputField mb-6"
                      title={"Company Name"}
                      value={formFields.companyName.value}
                      attrName={"companyName"}
                      value_update={updateFieldsState}
                      warn_status={formFields.companyName.warn_status}
                      error_message="Enter Company Name"
                    />
                  </Grid>
                  <Grid xs={12} md={6}>
                    <RKTextField
                      class_name="inputField mb-6"
                      title={"Email Address"}
                      value={formFields.email.value}
                      attrName={"email"}
                      value_update={updateFieldsState}
                      warn_status={formFields.email.warn_status}
                      error_message="Enter valid email"
                    />
                  </Grid>
                  <Grid xs={12} md={6}>
                    <RKTextField
                      class_name="inputField mb-6"
                      title={"Mobile"}
                      value={formFields.mobile.value}
                      attrName={"mobile"}
                      value_update={updateFieldsState}
                      warn_status={formFields.mobile.warn_status}
                      max_length={10}
                      validation_type="NUMBER"
                      error_message="Enter mobile number"
                    />
                  </Grid>
                  <Grid xs={12}>
                    <SelectDropdown
                      attrName={"claimType"}
                      class_name="inputField mb-6"
                      title="Claim Type"
                      value={formFields.claimType.value}
                      value_update={updateFieldsState}
                      warn_status={formFields.claimType.warn_status}
                      error_message={"Select Claim type"}
                      data={[
                        {
                          label: "Property & Engineering Insurance",
                          value: "Property & Engineering Insurance",
                        },
                        {
                          label:
                            "Business Interruption Insurance (FLOP & MLOP)",
                          value:
                            "Business Interruption Insurance (FLOP & MLOP)",
                        },
                        {
                          label: "Marine Cargo Insurance",
                          value: "Marine Cargo Insurance",
                        },
                        {
                          label: "Liability Insurance",
                          value: "Liability Insurance",
                        },
                        {
                          label: "Engineering/Project Insurance",
                          value: "Engineering/Project Insurance",
                        },
                        {
                          label: "Employee Benefits Insurance",
                          value: "Employee Benefits Insurance",
                        },
                        {
                          label: "Others",
                          value: "Others",
                        },
                      ]}
                    />
                  </Grid>
                  {formFields.claimType.value === "Others" && (
                    <Grid xs={12}>
                      <RKTextField
                        class_name="inputField mb-6"
                        title={"Manual"}
                        value={""}
                        attrName={""}
                        value_update={updateFieldsState}
                        warn_status={false}
                        error_message=""
                      />
                    </Grid>
                  )}
                  <Grid xs={12}>
                    <RKTextField
                      class_name="inputField mb-6"
                      title={"Additional Information you want to share "}
                      value={formFields.additionalInfo.value}
                      attrName={"additionalInfo"}
                      value_update={updateFieldsState}
                      warn_status={false}
                    />
                  </Grid>
                </Grid>
                <Grid container columnSpacing={3} className="mb-8">
                  <Grid xs={12} className="mb-6">
                    <h6 className="mb-2">
                      Attach documents related to the claim
                    </h6>
                    <DocUpload
                      buttonLabel={
                        formFields.policyDoc.fileName || "Choose file"
                      }
                      onFileSelect={(files) =>
                        handleFileSelect(files, "policyDoc")
                      }
                      warn_status={formFields.policyDoc.warn_status}
                      error_message={"Upload file"}
                    />
                  </Grid>
                  <Grid xs={12} className="mb-6">
                    <CustomCheckbox
                      label={
                        "We declare that all the above details are true to the best of our knowledge. We hereby appoint Amaze Insurance Brokers Pvt Ltd to act as Claims Consultant under our cited claim and authorize them to represent us with the insurance company or Surveyors or any other entities for the said Claim to render their services n accordance with IRDA (insurance Brokers) Regulations 2018. We confirm that the policy under which the cited claim is lodged is not placed through any other insurance broker/ We attach No Objection from the insurance Broker through which the policy is placed. We hereby agree to pay the mutually agreed Consultancy Fee to Amaze Insurance Brokers in accordance with IRDA (insurance Brokers) Regulations 2018."
                      }
                      attrName={"termsAndConditions"}
                      value={formFields.termsAndConditions.value}
                      // value_update={handleCheckboxChange}
                      value_update={updateFieldsState}
                    />
                  </Grid>
                </Grid>
                <Grid container columnSpacing={3}>
                  <Grid xs={12} textAlign={"center"}>
                    <CustomButton
                      className="primaryBtn"
                      fullWidth={false}
                      variant="contained"
                      text="Submit Your Query"
                      onClick={() => validateForm()}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default ClaimsConsultancyServices;

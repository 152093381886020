import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { COMMON_ROUTES } from "../Path/CommonPaths";
import HomePage from "../../Pages/Desktop/Homepage/HomePage";
import Aboutus from "../../Pages/Desktop/CMSPages/Aboutus/Aboutus";
import TermsConditions from "../../Pages/Desktop/CMSPages/TermsConditions/TermsConditions";
import WhyChooseUs from "../../Pages/Desktop/CMSPages/WhyChooseUs/WhyChooseUs";
import OurServices from "../../Pages/Desktop/CMSPages/OurServices/OurServices";
import OurSectors from "../../Pages/Desktop/CMSPages/OurSectors/OurSectors";
import PrivacyPolicy from "../../Pages/Desktop/CMSPages/PrivacyPolicy/PrivacyPolicy";
import Contactus from "../../Pages/Desktop/CMSPages/Contactus/Contactus";
import RuralInsurance from "../../Pages/Desktop/ProductPages/RuralInsurance/RuralInsurance";
import CropInsurance from "../../Pages/Desktop/ProductPages/CropInsurance/CropInsurance";
import WeatherInsurance from "../../Pages/Desktop/ProductPages/WeatherInsurance/WeatherInsurance";
import IPAR from "../../Pages/Desktop/IPAR/IPAR";
import IPARForm from "../../Pages/Desktop/IPAR/IPARForm/IPARForm";
import ClaimsConsultancy from "../../Pages/Desktop/ClaimsConsultancy/ClaimsConsultancy";
import ClaimsConsultancyServices from "../../Pages/Desktop/ClaimsConsultancy/ClaimsConsultancyServices/ClaimsConsultancyServices";

function CommonRoutes() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <Routes>
        <Route path={COMMON_ROUTES.HOME} element={<HomePage />} />
        <Route path={COMMON_ROUTES.ABOUT_US} element={<Aboutus />} />
        <Route path={COMMON_ROUTES.CONTACT} element={<Contactus />} />
        <Route
          path={COMMON_ROUTES.PRIVACY_POLICY}
          element={<PrivacyPolicy />}
        />
        <Route path={COMMON_ROUTES.OUR_SECTORS} element={<OurSectors />} />
        <Route path={COMMON_ROUTES.OUR_SERVICES} element={<OurServices />} />
        <Route path={COMMON_ROUTES.WHY_CHOOSE} element={<WhyChooseUs />} />
        <Route
          path={COMMON_ROUTES.TERM_AND_CONDTION}
          element={<TermsConditions />}
        />
        <Route
          path={COMMON_ROUTES.RURAL_INSURANCE}
          element={<RuralInsurance />}
        />
        <Route
          path={COMMON_ROUTES.CROP_INSURANCE}
          element={<CropInsurance />}
        />
        <Route
          path={COMMON_ROUTES.WEATHER_INSURANCE}
          element={<WeatherInsurance />}
        />
        <Route path={COMMON_ROUTES.IPAR} element={<IPAR />} />
        <Route path={COMMON_ROUTES.IPAR_FORM} element={<IPARForm />} />
        <Route
          path={COMMON_ROUTES.CLAIMS_CONSULTANCY}
          element={<ClaimsConsultancy />}
        />
        <Route
          path={COMMON_ROUTES.CLAIMS_CONSULTANCY_SERVICES}
          element={<ClaimsConsultancyServices />}
        />
      </Routes>
    </div>
  );
}

export default CommonRoutes;
